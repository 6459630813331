import { render } from "./Users.vue?vue&type=template&id=f5554e7e"
import script from "./Users.vue?vue&type=script&lang=js"
export * from "./Users.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f5554e7e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f5554e7e', script)) {
    api.reload('f5554e7e', script)
  }
  
  module.hot.accept("./Users.vue?vue&type=template&id=f5554e7e", () => {
    api.rerender('f5554e7e', render)
  })

}

script.__file = "src/components/users/Users.vue"

export default script